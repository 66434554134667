<template>
  <div class="file-container">
      <div class="file-box">
         <div class="file-box-top">
             <div class="file-tox-top1">
                 <div>
                     <input v-model="fileName" type="text" placeholder="请输入文件名称">
                     <img @click="fileSearch" :src="require('/static/images/orderSearch.png')" alt="">
                 </div>
             </div>
             <div class="file-box-top2">
                 <span @click="del">删除</span>
                 <span @click="laod">下载</span>
             </div>
         </div>
         <div class="file-box-list">
             <div v-for="(v,k) in fileArr" :key="k">
                 <div>
                    <el-checkbox :checked="v.isSelect" v-model="v.isSelect" class="file-list-box-check"></el-checkbox>
                </div>
                <div class="file-box-list-title">
                    <div>{{v.docName}}</div>
                    <!-- <div>{{v.dollNameInfo}}</div> -->
                </div>
                <div>{{v.createTime}}</div>
             </div>
         </div>
      </div>
      <div style="clear:both;"></div>
  </div>
</template>

<script>

export default {
  name: 'file',
  data(){
      return{
        fileName:'',
        fileArr:[]
      }
  },
  components: {
  },
  watch:{
      
  },
  methods:{
      fileSearch(){
          this.getdata()
      },
      laod(){
          var newList = this.fileArr.filter(function (val) { return val.isSelect});
          if(newList.length == 0){
                    this.$alert('请选择要下载的文件','温馨提醒',{
                        confirmButtonText: '确定'
                    })
                    return false
                }else{
                    for(var i = 0;i<newList.length;i++){
                        window.open(newList[i].docUrl, '_blank');
                    }
                }
      },
     del(){
         var newList = this.fileArr.filter(function (val) { return val.isSelect});
                if(newList.length == 0){
                    this.$alert('请选择要删除的文件','温馨提醒',{
                        confirmButtonText: '确定'
                    })
                    return false
                }
        this.$confirm('确定要删除该文件吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
            var arr = [];
            for(var i = 0;i<newList.length;i++){
                arr.push(newList[i].docCode)
            }
          this.$http.post(this.GLOBAL.new_url+'/user/service/deleteDocRecord',{
              userCode:this.$cookie.getCookie('u_id'),
              docCode:arr.join(",")
          }).then( res => {
         
          if(res.data.code == '200'){
              this.$message({
                    type: 'success',
                    message: '删除成功!'
             });
            this.getdata();
          }
        })
          
        }).catch(() => {
        //   this.$message({
        //     type: 'info',
        //     message: '已取消删除'
        //   });
        });
      },
      getdata(){
          this.$http.post(this.GLOBAL.new_url+'/user/service/getDocumentList',{
                userCode:this.$cookie.getCookie('u_id'),
                docName:this.fileName
            },{
                            headers:{
                                AuthToken:this.$cookie.getCookie('token'),
                                'X-Request-Origin':'xcx'
                            }
                                        }).then(res=>{
                  console.log(res)
                if(res.data.code =='200'){
                    this.fileArr = res.data.data;
                    var _this=this;
                        this.fileArr.map(function (item) {
                            _this.$set(item, 'isSelect', false);
                        })
                }
            })
      }
  },
  mounted(){
      this.getdata();
  }
}

</script>

<style scoped>
    .file-box{
        width:1015px;
        float: left;
        margin: 12px 0 0 16px;
        min-height: 1100px;
        background-color: #fff;
        padding: 32px 65px 0;
        box-sizing: border-box;
    }
   .file-box-top{
       margin-bottom: 30px;
       height: 28px;
       border-bottom:1px solid #F2F2F2;
   }
   .file-box-top>div:nth-of-type(1){
       float: left;
       margin-left: 550px;
   }
   .file-box-top>div:nth-of-type(2){
       float: right;
   }
    .file-tox-top1>div{
        position: relative;
    }
    .file-tox-top1>div>input{
        width: 128px;
        height: 24px;
        padding:0 20px 0 10px;
        border-radius: 4px;
        border: 1px solid #D6D6D6;
        outline-color: #EB5E00;
    }
    .file-tox-top1>div>img{
        width: 14px;
        height: 14px;
        cursor: pointer;
        position: absolute;
        top:6px;
        right: 6px;
    }
   .file-box-top2>span{
       text-align: center;
       display:inline-block;
       width: 64px;
       height: 24px;
       line-height: 24px;
       font-size: 14px;
       cursor: pointer;
       border-radius: 3px;
   }
   .file-box-top2>span:nth-of-type(1){
        color: #F96009;
        background: #FBEFE9;
        margin-right: 16px;
   }
   .file-box-top2>span:nth-of-type(2){
        color:#FFFFFF;
        background:  #F99600;
   }
   .file-box-list>div{
       margin-bottom: 60px;
       height: 50px;
   }
   .file-box-list>div>div{
       float: left;
       height: 50px;
   }
    .file-box-list>div>div:nth-of-type(1){
        line-height: 50px;
    }
   .file-box-list>div>div:nth-of-type(3){
        float: right;
        line-height: 85px;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        overflow: hidden;
   }
   .file-box-list-title{
       margin-left: 30px;
       font-size: 14px;
       font-weight: 400;
       color: #666666;
   }
   .file-box-list-title>div:nth-of-type(1){
       height: 50px;
       line-height: 50px;
       font-weight: 500;
       color: #333333;
   }
</style>
<style>
    .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #EB5E00;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #EB5E00;
    border-color: #EB5E00;
  }
</style>


